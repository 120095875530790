import React from "react";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    top: 0,
    left: 0,
    zIndex: 2000,
    padding: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    position: "fixed",
    alignItems: "center",
    backgroundColor: "#fff",
    flexDirection: "column",
    justifyContent: "center",
    // backgroundColor: theme.palette.background.default,
  },
  loader: {
    width: 300,
    maxWidth: "100%",
    margin: "auto",
    [theme.breakpoints.down("xs")]: { width: "180px" },
  },
  progressBar: { height: "3px" },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box width={300} align="center">
        <img
          alt="loader"
          className={classes.loader}
          src={require("../assets/images/AcrossLogo.png")}
        />
      </Box>
    </div>
  );
}
