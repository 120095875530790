import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SVG_WEBSITE_LOGO from "../../assets/images/AcrossLogo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    position: "relative",
    zIndex: "1",
    overflow: "hidden",
  },
  LoginLayout: {
    minHeight: "calc(100vh - 0px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  logoDrawer: {
    width: "350px",

    [theme.breakpoints.down("sm")]: {
      width: "330px",
    },
  },
  logoBox: {
    marginBottom: "25px",
    cursor: "pointer",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Box className="loginLeftSahdow"></Box>
      <Box className="loginRightSahdow"></Box>

      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.LoginLayout}>
        <Box onClick={() => history.push("/")} className={classes.logoBox}>
          <Box>
            <img
              alt="Logo"
              src={SVG_WEBSITE_LOGO}
              style={{ width: 350, marginBottom: -35 }}
            />
          </Box>
        </Box>
        {children}
      </div>
    </div>
  );
};

export default LoginLayout;
