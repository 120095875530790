import {
  Box,
  List,
  Slide,
  Drawer,
  Hidden,
  Button,
  Dialog,
  makeStyles,
  Typography,
  ListSubheader,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import {
  Home,
  People,
  ViewList,
  BarChart,
  ViewQuilt,
  TwoWheeler,
  LocalOffer,
  NoteAddOutlined,
  AddCircleOutlineOutlined,
  FormatListBulletedOutlined,
} from "@material-ui/icons";
import NavItem from "./NavItem";
import PropTypes from "prop-types";
import { User } from "react-feather";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useLocation, matchPath, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_REDUX_STATE } from "../../../redux/CommonRedux";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SVG_WEBSITE_LOGO from "../../../assets/images/AcrossLogo.png";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

const dealerSections = [
  {
    items: [
      {
        title: "Create Policy",
        icon: NoteAddOutlined,
        href: "/createPolicy",
      },
      {
        // title: "View Policies (OPS)",
        title: "View PDF",
        icon: FormatListBulletedOutlined,
        href: "/viewPolicy",
      },
      {
        title: "Add Balance",
        icon: AddCircleOutlineOutlined,
        href: "/addBalance",
      },

      {
        title: "Dealer MIS",
        icon: User,
        href: "/dealerMis",
      },
    ],
  },
];

const superAdminSections = [
  {
    items: [
      // Dashboard
      {
        title: "Dashboard",
        icon: Home,
        href: "/admin-dashboard",
      },

      // Create Dealer
      {
        title: "Create Dealer",
        icon: AddCircleOutlineIcon,
        href: "/admin-create-dealer",
      },

      // View Dealer
      {
        title: "View Dealers",
        icon: People,
        // href: "/admin-viewedit-dealer",
        href: "/admin-view-all-dealer",
      },

      // Discount
      {
        title: "Discount",
        icon: LocalOffer,
        href: "/admin-discount",
      },

      // Add Policy
      {
        title: "View Policy",
        icon: ViewQuilt,
        href: "/admin-view-policy",
      },

      // Create Vehicle
      {
        title: "Create Vehicle",
        icon: TwoWheeler,
        href: "/admin-create-vehicle",
      },

      // View Vehicles
      {
        title: "View Vehicles",
        icon: ViewList,
        href: "/admin-viewedit-vehicle",
      },

      // View MIS
      {
        title: "View MIS",
        icon: BarChart,
        href: "/admin-report-analytics",
      },

      // OPS
      {
        title: "Add Policy",
        icon: AddCircleOutlineIcon,
        href: "/admin-add-policy",
      },

      // View Benefits
      {
        title: "View Benefits",
        icon: ViewQuilt,
        href: "/admin-view-benefits",
      },

      // Wallet
      {
        title: "Wallet",
        icon: AccountBalanceWalletIcon,
        href: "/admin-wallet",
      },

      // Profile
      {
        title: "Profile",
        icon: AccountCircleIcon,
        href: "/admin-profile",
      },
    ],
  },
];

const adminSections = [
  {
    items: [
      // Dashboard
      {
        title: "Dashboard",
        icon: Home,
        href: "/admin-dashboard",
      },

      // Create Dealer
      {
        title: "Create User",
        icon: AddCircleOutlineIcon,
        href: "/admin-create-dealer",
      },

      // View Dealer
      {
        title: "View Dealers",
        icon: People,
        // href: "/admin-viewedit-dealer",
        href: "/admin-view-all-dealer",
      },

      // Discount
      {
        title: "Discount",
        icon: LocalOffer,
        href: "/admin-discount",
      },

      // Add Policy
      {
        title: "View Policy",
        icon: ViewQuilt,
        href: "/admin-view-policy",
      },

      // Create Vehicle
      // {
      //   title: "Create Vehicle",
      //   icon: TwoWheeler,
      //   href: "/admin-create-vehicle",
      // },

      // View Vehicles
      // {
      //   title: "View Vehicles",
      //   icon: ViewList,
      //   href: "/admin-viewedit-vehicle",
      // },

      // View MIS
      {
        title: "View MIS",
        icon: BarChart,
        href: "/admin-report-analytics",
      },

      // OPS
      // {
      //   title: "Add Policy",
      //   icon: AddCircleOutlineIcon,
      //   href: "/admin-add-policy",
      // },

      // View Benefits
      {
        title: "View Benefits",
        icon: ViewQuilt,
        href: "/admin-view-benefits",
      },

      // Wallet
      {
        title: "Wallet",
        icon: AccountBalanceWalletIcon,
        href: "/admin-wallet",
      },

      // Profile
      {
        title: "Profile",
        icon: AccountCircleIcon,
        href: "/admin-profile",
      },
    ],
  },
];

const opsSections = [
  {
    items: [
      // Dashboard
      {
        title: "Dashboard",
        icon: Home,
        href: "/admin-dashboard",
      },

      // Create Dealer
      {
        title: "Create Dealer",
        icon: AddCircleOutlineIcon,
        href: "/admin-create-dealer",
      },

      // View Dealer
      {
        title: "View Dealers",
        icon: People,
        // href: "/admin-viewedit-dealer",
        href: "/admin-view-all-dealer",
      },

      // Add Policy
      {
        title: "View Policy",
        icon: ViewQuilt,
        href: "/admin-view-policy",
      },

      // View MIS
      {
        title: "View MIS",
        icon: BarChart,
        href: "/admin-report-analytics",
      },

      // OPS
      {
        title: "Add Policy",
        icon: AddCircleOutlineIcon,
        href: "/admin-add-policy",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    // background: "#F5F5F5",
    background: "#fff",
  },
  logoDrawer: {
    width: "230px",
  },
  desktopDrawer: {
    top: "106px",
    width: "245px",
    height: "100% ",
    padding: "10px",
    marginLeft: "17px",
    // margin: "5px 10px 10px 15px",
    // background: "#F5F5F5",
    background: "#fff",
    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginTop: "0px",
    // marginLeft: "13px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",

    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "35%",
    bottom: "-250px",
  },
  logoutButton: {
    left: "35px",
    // bottom: "100px",
    bottom: "30px",
    display: "flex",
    position: "absolute",
    fontSize: "17px",
    background: "transparent",
    alignItems: "center",
    fontWeight: "400",
    justifyContent: "start",
    color: "#39aed",
    [theme.breakpoints.down("xs")]: {
      bottom: "-80px",
      // left: "37px",
      left: "57px",
    },
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
  LogoutTitleTxt: {
    color: "black",
    fontWeight: "300",
    marginBottom: "16px",
    fontFamily: "Sora, sans-serif",
  },
  LogoutLabelTxt: {
    fontFamily: "'Sora', sans-serif",
    color: "black",
  },
  ConfirmBtnContainer: {
    width: 120,
    padding: 12,
    color: "white",
    borderRadius: 30,
    fontFamily: "unset",
    backgroundColor: "#FFAC1C",
  },
  CancelBtnContainer: {
    width: 120,
    padding: 12,
    color: "black",
    borderRadius: 30,
    fontFamily: "unset",
    backgroundColor: "white",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLogout, setIsLogout] = useState(false);

  // ****** Redux Data ******
  const AuthType = useSelector((state) => state?.auth?.type);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  // ****** Logout Func ******
  const onLogout = async () => {
    dispatch(CLEAR_REDUX_STATE.CLEAR_LOGIN_DETAILS());
    dispatch(CLEAR_REDUX_STATE.CLEAR_BALANCE_HISTORY());
    dispatch(CLEAR_REDUX_STATE.CLEAR_CALCULATOR_DETAILS());
    dispatch(CLEAR_REDUX_STATE.CLEAR_DEALER_MIS_HISTORY());
    dispatch(CLEAR_REDUX_STATE.CLEAR_INSURANCE_DETAILS());
    dispatch(CLEAR_REDUX_STATE.CLEAR_PREVIEW_POLICY_LIST());
    dispatch(CLEAR_REDUX_STATE.CLEAR_SAVED_POLICY_ID());
    dispatch(CLEAR_REDUX_STATE.CLEAR_USER_BALANCE());
    toast.success("Logout successful!");
    history.push("/login");
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox">
            <Box>
              <img
                alt="Logo"
                src={SVG_WEBSITE_LOGO}
                style={{ width: 180, marginLeft: 15 }}
              />
            </Box>

            <>
              {AuthType === "Admin"
                ? adminSections?.map((section, i) => (
                    <List
                      key={`menu${i}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {section.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        img: section.img,
                        items: section.items,
                        pathname: location.pathname,
                      })}
                    </List>
                  ))
                : AuthType === "OPS"
                ? opsSections?.map((section, i) => (
                    <List
                      key={`menu${i}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {section.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        img: section.img,
                        items: section.items,
                        pathname: location.pathname,
                      })}
                    </List>
                  ))
                : AuthType === "SuperAdmin"
                ? superAdminSections?.map((section, i) => (
                    <List
                      key={`menu${i}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {section.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        img: section.img,
                        items: section.items,
                        pathname: location.pathname,
                      })}
                    </List>
                  ))
                : dealerSections?.map((section, i) => (
                    <List
                      key={`menu${i}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {section.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        img: section.img,
                        items: section.items,
                        pathname: location.pathname,
                      })}
                    </List>
                  ))}
            </>
          </Box>
        </Box>

        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <ExitToAppIcon
            width="60px"
            height="60px"
            style={{ color: "red", fontSize: "30px" }}
          />
          &nbsp;{" "}
          <Typography variant="body2" style={{ color: "black" }}>
            Logout
          </Typography>
        </Button>

        {isLogout && (
          <Dialog
            fullWidth
            keepMounted
            maxWidth="sm"
            open={isLogout}
            className={classes.dailogOpen}
            TransitionComponent={Transition}
            onClose={() => setIsLogout(false)}
          >
            <Box className="newModalBorder">
              <Box className="mainbox1" style={{ backgroundColor: "white" }}>
                <DialogContent>
                  <Box className={classes.dialougTitle} align="center">
                    <Typography variant="h2" className={classes.LogoutTitleTxt}>
                      Logout
                    </Typography>

                    <Typography
                      variant="body2"
                      className={classes.LogoutLabelTxt}
                    >
                      Are you sure, you want to logout?
                    </Typography>
                  </Box>
                </DialogContent>
                <DialogActions
                  style={{ alignContent: "center", justifyContent: "center" }}
                >
                  <Box mt={2} mb={2} maxWidth="400px">
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() => onLogout()}
                      className={classes.ConfirmBtnContainer}
                    >
                      Confirm
                    </Button>
                    &nbsp; &nbsp;
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() => setIsLogout(false)}
                      className={classes.CancelBtnContainer}
                    >
                      Cancel
                    </Button>
                  </Box>
                </DialogActions>
              </Box>
            </Box>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={openMobile}
          variant="temporary"
          onClose={onMobileClose}
          classes={{ paper: classes.mobileDrawer }}
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          open
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.desktopDrawer }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
